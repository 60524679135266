import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logo from 'containers/hub-page/views/sections/navigations/logo';
import SearchBar from 'containers/hub-page/views/sections/navigations/search-bar';
import DesktopNavLinks from 'containers/hub-page/views/sections/navigations/desktop/nav-links';
import DesktopAuthLinks from 'containers/hub-page/views/sections/navigations/desktop/auth-links';
import MobileNavLinks from 'containers/hub-page/views/sections/navigations/mobile/nav-links';
import MobileAuthLinks from 'containers/hub-page/views/sections/navigations/mobile/auth-links';
import HamburgerIcon from 'components/hub-page/views/sections/navigations/hamburger-icon';
import getLinkColor from 'lib/link-color';
import ProfileLinks from './mobile/profile-links';
import UserAvatar from './user-avatar';
import { withTranslation } from 'i18n';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      linkColor: '#1890FF',
      isMobileMenuVisible: false,
      isProfileMenuVisible: false,
    };

    this.handleMobileMenuVisibility =
      this.handleMobileMenuVisibility.bind(this);
    this.handleProfileMenuVisibility =
      this.handleProfileMenuVisibility.bind(this);
  }

  componentDidMount() {
    this.getAllLinkColor();
  }

  async getAllLinkColor() {
    const { themeName, theme, revision, layout } = this.props;
    const linkColor = await getLinkColor(theme, themeName, revision, layout);
    this.setState({ linkColor: linkColor, brandColor: theme.brandColor });
  }

  handleMobileMenuVisibility() {
    this.setState({
      isMobileMenuVisible: !this.state.isMobileMenuVisible,
      isProfileMenuVisible: false,
    });
  }

  handleProfileMenuVisibility() {
    this.setState({
      isProfileMenuVisible: !this.state.isProfileMenuVisible,
      isMobileMenuVisible: false,
    });
  }

  desktopAuthLinksNode() {
    return <DesktopAuthLinks sectionId={this.props.sectionId} />;
  }

  desktopMenuNode() {
    const { t } = this.props;
    return (
      <div
        className="ehq-participant-header ehq-participant-header--desktop-menu"
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.textColor,
          fontFamily: this.props.fontFamily,
        }}
        data-testid={this.props.dataTestId}
      >
        <a href="#hubpage-main" class="skip-to-content">
          {t('common:common.skipToContent')}
        </a>
        <div className="ehq-participant-header__left-menu">
          <Logo />
        </div>
        <div className="ehq-participant-header__right-menu">
          <DesktopNavLinks sectionId={this.props.sectionId} />
          <SearchBar sectionId={this.props.sectionId} />
          {this.desktopAuthLinksNode()}
        </div>
      </div>
    );
  }

  mobileAuthLinksNode() {
    return <MobileAuthLinks sectionId={this.props.sectionId} />;
  }

  loadHeader() {
    return this.props.user.login ? (
      <div className="ehq-participant-header ehq-participant-header__mobile-menu-items ehq-participant-mobile-over">
        {this.mobileAuthLinksNode()}
      </div>
    ) : (
      <div className="ehq-participant-header__mobile-menu-items">
        <MobileNavLinks sectionId={this.props.sectionId} />
        {this.mobileAuthLinksNode()}
        <SearchBar sectionId={this.props.sectionId} />
      </div>
    );
  }

  loadProfileMenu = () => {
    return this.props.user.login ? (
      <div
        className="ehq-participant-header ehq-participant-header__mobile-menu-items ehq-participant-mobile-over"
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.textColor,
          fontFamily: this.props.fontFamily,
        }}
      >
        <ProfileLinks />
      </div>
    ) : (
      <div
        className="ehq-participant-header__mobile-menu-items"
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.textColor,
          fontFamily: this.props.fontFamily,
        }}
      >
        <MobileNavLinks sectionId="0" loggedInUser="admin" />
        <MobileAuthLinks />
        <SearchBar />
      </div>
    );
  };

  mobileMenuNode() {
    const { t, nonce } = this.props;
    return (
      <div
        className="ehq-participant-header ehq-participant-header--mobile-menu"
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.textColor,
          fontFamily: this.props.fontFamily,
        }}
        data-testid={this.props.dataTestId}
      >
        <a href="#hubpage-main" class="skip-to-content">
          {t('common:common.skipToContent')}
        </a>
        <div className="ehq-participant-header__logo-container">
          <Logo />
          <span>
            {this.props.user.login && (
              <button
                className="avatar-button"
                onClick={this.handleProfileMenuVisibility}
                aria-expanded={this.state.isProfileMenuVisible}
              >
                <UserAvatar nonce={nonce} />
              </button>
            )}
            <HamburgerIcon
              onClick={this.handleMobileMenuVisibility}
              isMobileMenuVisible={this.state.isMobileMenuVisible}
            />
          </span>
        </div>
        {this.state.isMobileMenuVisible ? this.loadHeader() : false}
        {this.state.isProfileMenuVisible ? this.loadProfileMenu() : false}
      </div>
    );
  }

  headerNode() {
    return (
      <>
        {this.desktopMenuNode()}
        {this.mobileMenuNode()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.headerNode()}
        <style>
          {`
            .user-dropdown a,
            .user-dropdown .link-color,
            .user-dropdown a:hover,
            .user-dropdown a:visited,
            .user-dropdown a:focus {
              color: ${this.state.linkColor} !important;
            }
            .skip-to-content {
              position: absolute;
              z-index: 1000;
              left: 0;
              top: -30em;
              overflow: hidden;
              box-sizing: border-box;
            }

            .skip-to-content:focus {
              top: 1.4em;
              left: 1.4em;
              width: 10em;
              height: 3.5em;
              border-radius: 0.375rem;
              border-style: solid;
              font-weight:700;
              background-color: #FFF;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              text-decoration: underline;
              outline-offset: 0;
              outline: 3px solid rgba(66, 153, 225, 0.6);
              color: ${this.state.brandColor} !important;
            }
          `}
        </style>
      </>
    );
  }
}

Header.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  fontFamily: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  siteName: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  themeName: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  revision: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common'])(Header);
