import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { buildImgixUrl } from 'integrations/imgix.ts';

class LogoNode extends Component {
  render() {
    return (
      <div className="logo">
        {!!this.props.logoUrl && (
          <a href="/">
            <img
              alt={this.props.siteName}
              src={buildImgixUrl(this.props.logoUrl)}
              className="logo__image"
            />
          </a>
        )}
      </div>
    );
  }
}

LogoNode.propTypes = {
  siteName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

export default LogoNode;
