import { useSelector } from 'react-redux';
import { buildImgixUrl } from 'integrations/imgix.ts';

const UserAvatar = ({ nonce }) => {
  const { imageUrl } = useSelector((state) => state.currentUser.user);

  return imageUrl ? (
    <img
      alt="avatar-img"
      src={buildImgixUrl(imageUrl)}
      className="avatar user-settings-avatar-small"
      nonce={nonce}
    />
  ) : (
    <img
      alt="avatar-placeholder"
      src="/static/assets/images/participant-header/avatar-icon-big.svg"
      className="dummy-avatar-icon"
      nonce={nonce}
    />
  );
};
export default UserAvatar;
