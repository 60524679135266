import { connect } from 'react-redux';
import find from 'lodash/find';

import Header from 'components/hub-page/views/sections/navigations/header';
import fontFamilies from 'configs/font-families';

const mapStateToProps = (state, ownProps) => {
  const fetchedContentFont = find(
    fontFamilies,
    (f) => f.key === state.theme.theme.contentFont,
  );
  const homePageRevision = state.homePageRevision.revision.sections;

  const headerProps = {
    fontFamily:
      (fetchedContentFont && fetchedContentFont.name) || fontFamilies[0].name,
    logoUrl: state.currentSite.site.logoUrl,
    data:
      (homePageRevision &&
        state.homePageRevision.revision.sections[ownProps.sectionId].data[0]) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].data[0],
    siteName: state.currentSite.site.name,
    backgroundColor:
      (homePageRevision &&
        state.homePageRevision.revision.sections[ownProps.sectionId].theme
          .mainNavbarBackgroundColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme
        .mainNavbarBackgroundColor,
    textColor:
      (homePageRevision &&
        state.homePageRevision.revision.sections[ownProps.sectionId].theme
          .mainNavbarTextColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme
        .mainNavbarTextColor,
    user: state.currentUser.user,
    themeName: state.currentSite.site.themeName,
    theme: state.theme.theme,
    revision: state.homePageRevision.revision,
    layout: state.layout.layout,
    dataTestId: 'participant-hubs-views-navigations-section-header',
    nonce: state.hubPageRevision.nonce,
  };

  return headerProps;
};

export default connect(mapStateToProps)(Header);
